import * as React from 'react';
import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";

export default class ChartPastPerf extends React.Component {
    render() {
        return <Line
            datasetIdKey='id'
            data={{
                labels: ['2020-09-30',
                    '2020-10-30',
                    '2020-11-30',
                    '2020-12-30',
                    '2021-01-29',
                    '2021-02-26',
                    '2021-03-31',
                    '2021-04-30',
                    '2021-05-31',
                    '2021-06-30',
                    '2021-07-30',
                    '2021-08-31',
                    '2021-09-30',
                    '2021-10-29',
                    '2021-11-30',
                    '2021-12-31',
                    '2022-01-31',
                    '2022-02-28',
                    '2022-03-31',
                    '2022-04-29',
                    '2022-05-31',
                    '2022-06-30',
                    '2022-07-29',
                    '2022-08-31',
                    '2022-09-30',
                    '2022-10-31',
                    '2022-11-30',
                    '2022-12-31',
                    '2023-01-31',
                    '2023-02-28',
                    '2023-03-31',
                    '2023-04-28',
                    '2023-05-31',
                    '2023-06-30',
                    '2023-07-31',
                    '2023-08-31',
                    '2023-09-29',
                    '2023-10-31',
                    '2023-11-30',
                    '2023-12-29',
                    '2024-01-31',
                    '2024-02-29',
                    '2024-03-31',
                    '2024-04-30',
                    '2024-05-31',
                    '2024-06-28'],
                datasets: [
                    {
                        id: 1,
                        label: 'Indépendance et Expansion Europe Small A(C)',
                        data: [100.00,
                            92.81,
                            109.17,
                            117.82,
                            115.83,
                            123.82,
                            136.32,
                            140.18,
                            145.53,
                            150.23,
                            156.37,
                            162.25,
                            160.59,
                            166.55,
                            161.98,
                            174.16,
                            167.48,
                            167.53,
                            177.34,
                            173.51,
                            174.10,
                            157.68,
                            165.64,
                            158.28,
                            138.04,
                            148.59,
                            154.23,
                            157.67,
                            163.67,
                            167.51,
                            168.02,
                            169.14,
                            169.56,
                            172.02,
                            171.78,
                            169.30,
                            162.74,
                            155.82,
                            167.18,
                            178.64,
                            176.16,
                            179.90,
                            188.90,
                            188.87,
                            197.83,
                            186.88],
                        fill: false,
                        lineTension: 0,
                        pointBackgroundColor: "rgba(76, 163, 254,1)",
                        borderColor: "rgba(76, 163, 254,0.7)"
                    },
                    {
                        id: 2,
                        label: 'factorinvesting.eu',
                        data: [100.00,
                            98.54,
                            113.30,
                            122.67,
                            124.81,
                            134.71,
                            145.84,
                            154.23,
                            160.28,
                            161.06,
                            163.71,
                            168.12,
                            164.15,
                            168.68,
                            162.56,
                            170.92,
                            167.03,
                            161.54,
                            164.69,
                            164.25,
                            163.55,
                            151.34,
                            157.90,
                            154.26,
                            138.58,
                            148.82,
                            154.21,
                            153.99,
                            161.38,
                            163.53,
                            160.62,
                            159.63,
                            156.43,
                            159.64,
                            163.36,
                            159.76,
                            153.09,
                            149.07,
                            155.53,
                            162.48,
                            163.19,
                            163.37,
                            164.67,
                            166.11,
                            175.89,
                            168.30],
                        fill: false,
                        lineTension: 0,
                        pointBackgroundColor: "rgba(241, 96, 101,1)",
                        borderColor: "rgba(241, 96, 101,0.7)"
                    },
                    {
                        id: 3,
                        label: 'SPDR MSCI Europe Small Cap Value Weighted',
                        data: [100.00,
                            96.11,
                            118.97,
                            125.45,
                            125.41,
                            135.49,
                            145.04,
                            149.19,
                            153.69,
                            150.62,
                            152.74,
                            158.53,
                            154.51,
                            157.42,
                            151.73,
                            159.21,
                            156.68,
                            151.30,
                            153.67,
                            152.10,
                            152.15,
                            134.16,
                            141.47,
                            134.63,
                            119.35,
                            130.36,
                            139.41,
                            136.96,
                            148.84,
                            154.26,
                            145.47,
                            148.10,
                            144.43,
                            146.55,
                            152.42,
                            148.94,
                            145.99,
                            138.14,
                            149.06,
                            158.41,
                            157.32,
                            155.47,
                            162.99,
                            163.58,
                            173.27,
                            164.47],
                        fill: false,
                        lineTension: 0,
                        pointBackgroundColor: "rgba(154,205,50,1)",
                        borderColor: "rgba(154,205,50,0.7)"
                    },
                    {
                        id: 4,
                        label: 'iShares MSCI EMU Small Cap',
                        data: [100.00,
                            93.47,
                            112.03,
                            116.54,
                            118.68,
                            122.94,
                            129.84,
                            133.56,
                            137.08,
                            136.80,
                            138.86,
                            144.34,
                            139.62,
                            142.91,
                            138.30,
                            143.27,
                            135.91,
                            133.13,
                            135.62,
                            133.23,
                            134.20,
                            117.31,
                            122.81,
                            117.81,
                            104.18,
                            114.02,
                            120.92,
                            119.32,
                            130.50,
                            134.30,
                            128.69,
                            129.71,
                            127.06,
                            129.74,
                            133.84,
                            130.71,
                            125.43,
                            119.55,
                            129.51,
                            136.54,
                            134.86,
                            134.58,
                            141.20,
                            141.20,
                            147.24,
                            138.45],
                        fill: false,
                        lineTension: 0,
                        pointBackgroundColor: "rgba(255,218,185,1)",
                        borderColor: "rgba(255,218,185,0.7)"
                    },
                    {
                        id: 5,
                        label: 'MSCI EMU Micro Cap',
                        data: [100.00,
                            96.86,
                            115.17,
                            122.36,
                            126.98,
                            134.19,
                            139.26,
                            143.97,
                            147.79,
                            148.44,
                            149.67,
                            154.19,
                            150.04,
                            153.34,
                            149.00,
                            154.33,
                            146.25,
                            137.23,
                            140.82,
                            135.92,
                            134.39,
                            123.99,
                            126.47,
                            123.43,
                            109.33,
                            114.57,
                            119.31,
                            118.47,
                            126.36,
                            126.11,
                            122.93,
                            122.22,
                            121.67,
                            122.48,
                            121.97,
                            118.81,
                            111.46,
                            103.59,
                            109.72,
                            114.99,
                            113.51,
                            110.08,
                            112.06,
                            113.79,
                            120.29,
                            112.76],
                        fill: false,
                        lineTension: 0,
                        pointBackgroundColor: "rgba(128,128,128,1)",
                        borderColor: "rgba(128,128,128,0.7)"
                    },
                ],
            }}
        />
    }
}